import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import Box from '@material-ui/core/Box'
import { Offline } from 'react-detect-offline'

function OfflineComponent(props) {
  const { classes, ...otherProps } = props // eslint-disable-line no-unused-vars
  
  return (
    <Offline>
      <Box 
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.box}
        {...otherProps}
      >
        <icons.CloudOffIcon/>&nbsp;
        Ups! You seems to be offline.
        Please check your Internet connection...
      </Box>
    </Offline>
  )
}

export default OfflineComponent
