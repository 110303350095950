import Root from './Root'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { compose } from 'recompose'

export default compose(
  withStyles(styles),
  withRouter,
)(Root)
