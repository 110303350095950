import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'

// @ts-ignore
import { firebaseConfig } from 'config'

// Initialize Firebase instance
firebase.initializeApp(firebaseConfig)

if (process)  {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')  {
    console.log('NOTE: useFunctionsEmulator > http://localhost:5001 ENABLED')
    firebase.functions().useFunctionsEmulator('http://localhost:5001')
  }
}

export default firebase