import React from 'react'
import clsx from 'clsx'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, isEmpty, getFirebase } from 'react-redux-firebase'
import  * as routes from 'constants/routes'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserView } from 'react-device-detect'
import theme from 'theme'
import { useLocation } from 'react-router-dom'

function Navbar({ classes }) {
  const dispatch = useDispatch() // eslint-disable-line no-unused-vars
  const title = useSelector(state => _.get(state, 'navigation.title', '4G LTE'))
  const sidebar = useSelector(state => _.get(state, 'show.sidebar'))
  const firebase = getFirebase()
  const auth = useSelector(state => _.get(state, `firebase.auth`)) // eslint-disable-line no-unused-vars
  const admin = useSelector(state => _.get(state, `firebase.data.admins.${_.get(auth, 'uid', '-')}`))
  const location = useLocation() // eslint-disable-line no-unused-vars
  const query = new URLSearchParams(_.get(location, 'search')) // eslint-disable-line no-unused-vars
  
  return (
    <>  
      <CssBaseline />
      <div className={classes.toolbar}>
        &nbsp;
      </div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sidebar
        })}
        style={{
          zIndex: sidebar ? _.get(theme, 'zIndex.drawer', 0) - 1 : _.get(theme, 'zIndex.drawer', 0) + 1
        }}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Toolbar>
          <IconButton
            size={'small'}
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch({ type: 'SHOW_SIDEBAR' })}
            edge="start"
            className={clsx(classes.menuIconButton, {
              [classes.hide]: sidebar,
            })}
          >
            <icons.MenuOpenIcon />
          </IconButton>
          <Typography 
            className={classes.title}
            variant="h6"
            noWrap
          >
            &nbsp;
            {sidebar ? '' : title}
          </Typography>
          {(_.get(window, 'location.host', '').startsWith('admin') || _.get(window, 'location.pathname', '').startsWith('/admin')) &&
            <>
            {_.get(admin, 'enabled') &&
              <>
                &nbsp;|&nbsp;
                <IconButton
                  size={'small'}
                  className={classes.link}
                  component={Link}
                  to={routes.ADMIN_ROUTE}
                >
                  <icons.HomeIcon/>&nbsp;
                  <BrowserView>
                    Pocetna stranica
                  </BrowserView>
                </IconButton>
              </>
            }
            {(isLoaded(auth) && !isEmpty(auth)) && 
              // <AccountMenu />
              <>
                &nbsp;|&nbsp;
                <IconButton
                  size={'small'}
                  className={classes.link}
                  onClick={() => firebase.logout()}
                >
                  <icons.ExitToAppIcon/>&nbsp;
                  <BrowserView>
                    Odjava
                  </BrowserView>
                </IconButton>
              </>
            }
          </>
          }
          {(!_.get(window, 'location.host', '').startsWith('admin') && !_.get(window, 'location.pathname', '').startsWith('/admin')) &&
            <>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ACCOUNT_ROUTE}
              >
                <icons.PhoneIcon/>&nbsp;
                <BrowserView>
                  064 / 123 - 456- 78
                </BrowserView>
              </IconButton>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ACCOUNT_ROUTE}
              >
                <icons.FacebookIcon/>&nbsp;
              </IconButton>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ACCOUNT_ROUTE}
              >
                <icons.AccountCircleIcon/>&nbsp;
                <BrowserView>
                  Nalog
                </BrowserView>
              </IconButton>
            </>
          }
          {(isLoaded(auth) && isEmpty(auth)) && 
            <>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ROOT_ROUTE}>
                Prijava
              </IconButton>
            </>
          }
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Navbar
