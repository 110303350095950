
const styles = (theme) => ({
  ...theme.global,
  routes: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 5
  },
  
})
export default styles
