import { combineReducers } from 'redux'
import { without, omit } from 'lodash'
import { NOTIFICATION_SHOW, NOTIFICATION_DISMISS, FIREBASE_LISTENER_ERROR } from './actionTypes'

function notification(state = {}, action) {
  switch (action.type) {
    case NOTIFICATION_SHOW:
      return action.payload
    case FIREBASE_LISTENER_ERROR:
      // @ts-ignore
      return [...state, action.payload]
    case NOTIFICATION_DISMISS:
      return undefined
    default:
      return state
  }
}

function allIds(state = [], action) {
  switch (action.type) {
    case NOTIFICATION_SHOW:
      return [...state, action.payload.id]
    case FIREBASE_LISTENER_ERROR:
      return [...state, action.payload.code]
    case NOTIFICATION_DISMISS:
      return without(state, action.payload)
    default:
      return state
  }
}

function byId(state = {}, action) {
  switch (action.type) {
    case NOTIFICATION_SHOW:
      // console.log('action :', action);
      return {
        ...state,
        [action.payload.id]: notification(state[action.payload.id], action)
      }
    case FIREBASE_LISTENER_ERROR:
      return {
        ...state,
        [action.payload.code]: {
          id: action.payload.code,
          type: 'error',
          message: action.payload.toString()
        }
      }
    case NOTIFICATION_DISMISS:
      return omit(state, action.payload)
    default:
      return state
  }
}

export const notifications = combineReducers({ byId, allIds })

export default notifications
