import { compose } from 'recompose'
import { Loadable } from 'utils/components'
import { withRouter } from 'react-router-dom'
import  * as routes from 'constants/routes'
import { withStyles } from '@material-ui/styles'
import styles from './styles'

export default {
  routes: [{
    path: routes.ROOT_ROUTE,
    exact: true
  }],
  component: compose(
    withRouter,
    withStyles(styles),
  // @ts-ignore
  )(Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Client' */ './Client')
  }))
}