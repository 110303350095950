import { compose } from 'recompose'
import { Loadable } from 'utils/components'
import { withRouter } from 'react-router-dom'
import  * as routes from 'constants/routes'
import { withStyles } from '@material-ui/styles'
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import styles from './styles'

export default {
  routes: [{
    path: routes.ADMIN_ROUTE
  }],
  component: compose(
    withStyles(styles),
    withRouter,
  // @ts-ignore
  )(Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Admin' */ './Admin')
  }))
}