 import React from 'react'
 import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { Switch, Route } from 'react-router-dom'
import { useDispatch,  useSelector } from 'react-redux'  // eslint-disable-line no-unused-vars
import Navbar from 'components/nav/Navbar'
import { Notifications } from 'modules/notification'
import { renderRoutes } from 'utils/router'
import LoadingSpinnerModal from 'components/popup/LoadingSpinnerModal'
import Offline from 'components/general/Offline'
import Admin from 'routes/Admin'
import Client from 'routes/Client'

function Root({ classes, match, store }) {
  const show = useSelector(state => _.get(state, 'show'))

  React.useEffect(() => {
    // if(_.get(window, 'location.hostname') === '4glte.wasp-soft.com') {
    //     window.open(_.get(window, 'location.href').replace(_.get(window, 'location.hostname'), 'www.4glte.com'), '_self')
    // }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 
  
  return (
    <div>
      <Navbar />
      {1==2 &&
        <Offline />
      }
      <div className={classes.routes}>
        <Switch>
          {renderRoutes([
            Admin,
          ], match, { store })}
          <Route component={Client.component} />
        </Switch>
      </div>
      <Notifications />
      {_.get(show, 'spinner') &&
        <LoadingSpinnerModal/>
      }
    </div>
  )
}
export default Root
