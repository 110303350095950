import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import CircularProgress from '@material-ui/core/CircularProgress'

function LoadingSpinner({ classes, size }) {
  
  return (
    <div className={classes.box}>
      <div className={classes.progress}>
        <CircularProgress /*mode="indeterminate"*/ size={size || 80} />
      </div>
    </div>
  )
}

LoadingSpinner.propTypes = {
  size: PropTypes.number
}

export default LoadingSpinner
