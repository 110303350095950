import normalize from 'normalize-path'

export const ROOT_ROUTE = normalize(`/`)
export const ADMIN_ROUTE = normalize(`${ROOT_ROUTE}/admin`)
export const CLIENTS_ROUTE = normalize(`${ADMIN_ROUTE}/clients`)
export const SYNC_ROUTE = normalize(`${ADMIN_ROUTE}/sync`)
export const LOGS_ROUTE = normalize(`${ADMIN_ROUTE}/logs`)
export const ADMINS_ROUTE = normalize(`${ADMIN_ROUTE}/admins`)
export const PRODUCTS_ROUTE = normalize(`${ADMIN_ROUTE}/products`)
export const DISCOUNTS_ROUTE = normalize(`${ADMIN_ROUTE}/discounts`)
export const PRICES_ROUTE = normalize(`${ADMIN_ROUTE}/prices`)
export const VENDORS_ROUTE = normalize(`${ADMIN_ROUTE}/vendors`)
export const ORDERS_ROUTE = normalize(`${ADMIN_ROUTE}/orders`)
export const PAYMENTS_ROUTE = normalize(`${ADMIN_ROUTE}/payments`)
export const SHIPPING_ROUTE = normalize(`${ADMIN_ROUTE}/shipping`)
export const CART_ROUTE = normalize(`${ROOT_ROUTE}/cart`)
export const ACCOUNT_ROUTE = normalize(`${ROOT_ROUTE}/account`)