import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import Modal from '@material-ui/core/Modal'
import LoadingSpinner from 'components/popup/LoadingSpinner'

function LoadingSpinnerModal({ classes }) {
    
  return (
    <Modal
      className={classes.modal}
      open={true}
    >
      <>
        <LoadingSpinner />
      </>
    </Modal>
  )
}

export default LoadingSpinnerModal
