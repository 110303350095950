import store from 'store'

export const blinkSpinner = () => {
  // @ts-ignore
  store.dispatch({
    type: 'SHOW_SPINNER'
  })
  setTimeout(() => {
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
  }, 1000)
}