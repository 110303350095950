import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { ThemeProvider } from '@material-ui/core/styles'
import firebase from 'providers/firebase'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import theme from './theme'
import { reduxFirebase as rfConfig } from './config'
import Root from './routes'
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import store from './store'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={rfConfig}
          dispatch={store.dispatch} >
          <BrowserRouter>
            <Switch>
              <Route
                render={props => <Root store={store} />}
              />
            </Switch>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </ThemeProvider>
  )
}

App.propTypes = {
  
}

export default App
