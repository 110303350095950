
const styles = (theme) => ({
  ...theme.global,
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default styles
