import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { Route } from 'react-router-dom'
import normalize from 'normalize-path'

export const renderRoutes = (pages, match, parentProps) => {
  return pages.map((page, key) => {
    return (
      _.get(page, 'routes', []).map((route) => {
        if(normalize(_.get(route, 'path'))) {
          // console.log(_.get(route, 'path'))
          if(_.get(route, 'exact')) {
            return (
              <Route
                key={`${key}-${_.get(route, 'path')}`}
                exact
                path={normalize(`${_.get(route, 'path')}`)} // ${match.url}/
                render={props => (
                  <page.component 
                    {...parentProps} 
                    {...props} 
                    page={_.get(route, 'page')} 
                    path={_.get(route, 'path')} 
                  />
                )}
              />
            )
          }
          else {
            return (
              <Route
                key={`${key}-${_.get(route, 'path')}`}
                path={normalize(`${_.get(route, 'path')}`)} // ${match.url}/
                render={props => (
                  <page.component 
                    {...parentProps} 
                    {...props} 
                    page={_.get(route, 'page')} 
                    path={_.get(route, 'path')} 
                  />
                )}
              />
            )
          }
        }
        return null
      })
    )
  })
}
