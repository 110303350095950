
const styles = (theme) => ({
  ...theme.global,
  box: {
    display: 'flex',
    flexGrow: 1,
    height: '100%'
  },
  icon: {
    width: '100%',
    maxWidth: '13rem',
    // marginTop: '3rem',
    height: 'auto',
    cursor: 'pointer'
  }
})
export default styles
