import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  overrides: {
    MuiListItemAvatar: {
      root: {
        minWidth: 40,
      },
    },
    MuiList: {
      root: {
        width: '100%',
        position: 'relative',
        // overflow: 'auto',
      }
    },
    MuiListItemText: {
      root: {
        // overflow: 'auto',
        textOverflow: 'ellipsis',
      }
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: '#cccccc'
      }
    }
  },
  palette: {
    primary: {
      main: '#333333',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#cccccc'
    },
    error: {
      main: '#e74c3c'
    }
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    // @ts-ignore
    useNextVariants: true
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  global: {
    grid: {
      marginTop: 1,
      flexGrow: 1,
      width: '100%',
      borderRight: '1px solid #F2F2F2',
      borderLeft: '1px solid #F2F2F2',
      borderBottom: '1px solid #F2F2F2',
    },
    top: {
      maxHeight: '32vh', 
      overflow: 'auto',
    },
    middle: {
      maxHeight: '42vh', 
      overflow: 'auto',
    },
    bottom: {
      // height: '55vh', 
      overflow: 'auto'
    },
    full: {
      height: '89vh', 
      overflow: 'auto'
    },
    table: {
      
    },
    tableHeader: {
      backgroundColor: '#cccccc',
      fontWeight: 'bold'
    },
    tableBody: {
      fontSize: 14
    },
  }
})
export default theme