import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import * as actions from '../actions'

export default function useNotifications() {
  const dispatch = useDispatch() // eslint-disable-line no-unused-vars
  return useMemo(() => {
    return bindActionCreators(actions, dispatch)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}