import {  } from './actions'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { isMobile } from 'react-device-detect'

const initialState = {
	spinner: false,
	sidebar: !isMobile,
}
const reducer = ((state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_SPINNER':
			state.spinner = true
		break	
		case 'HIDE_SPINNER':
			state.spinner = false
		break	
		case 'SHOW_SIDEBAR':
			state.sidebar = true
		break	
		case 'HIDE_SIDEBAR':
			state.sidebar = false
		break	
		default: 
		
		break
	}
	return {
		...state
	}
})
export default reducer