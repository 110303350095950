
const styles = (theme) => ({
  ...theme.global,
  box: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '1rem',
    height: '100%'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%'
  }
})
export default styles
