export const ADMINS_REF = 'admins'
export const IMAGES_REF = 'images'
export const USERS_REF = 'users'
export const ORDERS_REF = 'orders'
export const PRODUCTS_REF = 'products'
export const GROUPS_REF = 'groups'
export const HIERARCHY_REF = 'hierarchy'
export const HISTORY_REF = 'history'
export const EMPTY_REF = 'empty'
export const VENDORS_REF = 'vendors'
export const PRICES_REF = 'prices'