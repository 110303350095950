import  * as refs from 'constants/firebaseRefs'

export const env = 'local'

// Config for firebase
export const firebaseConfig = {
  apiKey: "AIzaSyDjjsonI4QOFXDqtYkVxh9MwbpG2vHpFzA",
  authDomain: "lte-4gstore.firebaseapp.com",
  databaseURL: "https://lte-4gstore-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lte-4gstore",
  storageBucket: "lte-4gstore.appspot.com",
  messagingSenderId: "803124213106",
  appId: "1:803124213106:web:d1464cfbc50d3a066c99eb",
  measurementId: "G-NK9F3Z1K54"
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  userProfile: 'users',
  enableLogging: false, // enable/disable Firebase Database Logging
  profileFactory: (userData, profileData) => { // how profiles are stored in database
    // console.log('profileData :', profileData);
    // console.log('userData :', userData);
    profileData.uid = userData.uid
    if(userData.providerData) {
      profileData.admin = userData.uid
    }
    return profileData
  },
  profileParamsToPopulate: [{ 
    child: 'admin', 
    root: refs.ADMINS_REF
  }],
}

export default {
  env,
  firebaseConfig,
  reduxFirebase
}
